import passLockFilter from "./passLockFilter"
import recordFilter from "./record"

const companyLink = (el, type) => {
  let link

  if (type === "partner") {
    link =
      "/partners/" +
      decodeURIComponent(
        el
          .replace(/\./g, "")
          .replace(/&/g, "")
          .replace(/\s/g, "")
          .replace(/-/g, "")
          .replace(/é/g, "e")
      )
  } else if (type === "startup" && el.recordId) {
    link = passLockFilter(
      "/startups/" + recordFilter(el.recordId),
      el.data.password_protected
    )
  }

  return link
}

export default companyLink
