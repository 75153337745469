// v1 until 202103021654

// const publishFilter = ( el, toggle ) => {

//   let elArray = []

//   if (el && el.length > 0) {
//     elArray = el.filter(
//       ( e ) =>
//         e.data[ toggle ] === true
//     )

//     return elArray;
//   } else {
//     return false;
//   }

// }


// v1.1 published 202103021654

const publishFilter = (el, toggle) => {
  let elArray = [];

  if (el && el.length > 0) {
    
    if (toggle && toggle.length > 0 && typeof toggle === "object") {
      
      elArray = el.filter((e) => {
        let arrayReturns = [];
        
        toggle.forEach((t) => {
          arrayReturns.push(e.data[t] === true);
        });
        
        if (arrayReturns.length === toggle.length && arrayReturns.every(x => (x === true))) {
          return true
        } else {
          return false
        }
      
      });

    } else if (toggle && typeof toggle === "string") {
      elArray = el.filter(
        (e) => e.data[toggle] === true
      );
    } else {
    
      return false;
    
    }
    return elArray;
  } else {
    return false;
  }
};


export default publishFilter;