import React from 'react';
import ReactModal from 'react-modal';
import { Global, css } from '@emotion/react';
import { color } from "../styles/theme"
import { GatsbyImage } from "gatsby-plugin-image";
import Close from '../assets/svg/close.svg'

ReactModal.setAppElement('#___gatsby')
const customStyles = {
  overlay : {
    zIndex: '9999999',
  },
};

export const Modal = ({modalOpen, modalHandler, modalName, image}) => {

  return(
    <>
      <Global styles={css`
        .ReactModal__Overlay {
            opacity: 0;
            transition: opacity 250ms ease-in-out;
        }

        .ReactModal__Overlay--after-open{
            opacity: 1;
        }

        .ReactModal__Overlay--before-close{
            opacity: 0;
        }
      `}/>
      <ReactModal
        isOpen={modalOpen}
        contentLabel={modalName}
        closeTimeoutMS={250}
        style={customStyles}
        className={"ModalInner"}
      >
        
        <ImageModal modalHandler={modalHandler} image={image} />
      </ReactModal>
    </>
  )
}

export const ImageModal = ({modalHandler, image, forwardedRef}) => {

  return (
    <div css={css`
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    `}>
      <div role="button" tabIndex={0} onClick={modalHandler} aria-label="Close" onKeyDown={modalHandler} css={css`
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0,0,0,.75);
      `} />

      <div css={css`
        height: 100%;
        width: 100%;
      `}>
        <div css={css`
          background: ${color.main_dark};
          height: 3rem;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 1em;
        `}>
          <p css={css`
            color: white;
            margin-bottom: 0;
            font-size: .75em;
          `}>STARTUP AUTOBAHN &copy; 2020 - 2021</p>
          <button 
            onClick={modalHandler}
            css={css`
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              background: none;
              outline: none;
              border: none;
              svg {
                stroke: white;
              }
            `}
          >
            <Close />
          </button>
        </div>
        <div
          onClick={modalHandler}
          role="button"
          tabIndex={0}
          aria-label="Close"
          onKeyDown={modalHandler}
          css={css`
            width: 100%;
            position: relative;
            top: 3rem;
            height: calc(100% - 3rem);
            display: flex;
            align-items: center;
            justify-content: center;
          `}>
          <GatsbyImage
            alt=""
            image={image}
            objectFit="contain"
            objectPosition="50% 50%"
            css={css`
              height: 95%;
              width: 95%;
            `}
            imgStyle={{
              marginBottom: "0",
            }} />
        </div>
      </div>
    </div>
  );
};
